<label class="visually-hidden"
	[attr.for]="'AriaInput' + id">
	{{field.props.label}}
</label>
<ng-template
	[ngIf]="refreshCalendar === false">
	<p-calendar
		[(ngModel)]="displayDate"
		[formlyAttributes]="field"
		[inputStyleClass]="inputStyleClass"
		[showTime]="field.props.showTime"
		[disabled]="field.props.disabled"
		hourFormat="12"
		[minDate]="field.props.minDate"
		showIcon="true"
		showButtonBar="true"
		showSeconds="true"
		[appendTo]="field.props.appendTo"
		[panelStyleClass]="field.props.panelStyleClass"
		[placeholder]="field.props.placeholder"
		(onSelect)="dateChange($event)"
		(onInput)="dateChange($event)"
		(onClearClick)="dateChange($event)"
		[view]="field.props.view || 'date'"
		[dateFormat]="field.props.dateFormat || 'mm/dd/yy'">
	</p-calendar>
	<ng-template
		[ngIf]="field.props.quickDateSelection != null">
		<span
			[pTooltip]="field.props.quickDateSelection.tooltipOptions?.tooltipLabel"
			tooltipPosition="left"
			tooltipStyleClass="quick-date-tooltip">
			<button type="button"
				pButton
				pRipple
				class="p-button-outlined quick-date-selection ellipsis-text"
				[ngClass]="{
					'p-disabled': (field.props.quickDateSelection.disabled)
				}"
				[label]="field.props.quickDateSelection.label"
				[disabled]="field.props.quickDateSelection.disabled"
				(click)="displayDate = field.props.quickDateSelection.command($event); dateChange($event);">
			</button>
		</span>
	</ng-template>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>