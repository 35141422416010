/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A class representing static constant and read only values
 * for files.
 *
 * @export
 * @const FileConstants
 */
export class FileConstants {
	public static readonly fileTypes:
	{
		attachment: string;
		document: string;
		form: string;
	} = {
			attachment: 'Attachment',
			document: 'Document',
			form: 'Form',
		};
}