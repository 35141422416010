<div class="ui-g-12 no-vertical-padding">
	<app-file-progress
		*ngIf="progressVisible"
		[message]="progressMessage"
		[errorMessage]="errorMessage"
		[mode]="progressMode"
		[progress] = "progressAmount">
	</app-file-progress>

	<div *ngIf="editDocumentVisible"
		class="ui-g-12 text-left no-padding">
		<div class="ui-g-12 nested-display-subtitle no-horizontal-padding text-left">
			<div class="ellipsis-text"
				[pTooltip]="fileEntity.data.name"
				tooltipPosition="left"
				tooltipStyleClass="utility-menu-tooltip">
				Edit: {{fileEntity.data.name}}
			</div>
			<span class="nested-display-actions">
				<ng-template
					ngFor
					let-action
					[ngForOf]="itemActions">
					<span [ngClass]="{
							'selected-bottom-border': (action.id === 'edit')
						}">
						<app-common-icon-tooltip
							content="{{action.label}}"
							iconClass="fa fa-fw {{action.icon}} theme-color cursor-pointer"
							position="left"
							styleClass="utility-menu-tooltip"
							(iconClick)="action.command($event)">
						</app-common-icon-tooltip>
					</span>
				</ng-template>
			</span>
		</div>

		<p-scrollPanel styleClass="ui-g-12 no-vertical-padding edit-file-container">
			<div class="ui-g-12">
				<div class="input-label">
					<label for="category">
						<strong>
							Type
						</strong>
					</label>
				</div>
				<div name="category"
					class="theme-color-gray">
					{{fileCategory}}
				</div>
			</div>

			<div *ngIf="this.subType !== 'None'"
				class="ui-g-12 custom-field-wrapper">
				<div class="input-label">
					<label for="subTypes">
						<strong>
							Subtype
						</strong>
					</label>
				</div>
				<p-dropdown
					name="subType"
					inputId="subType"
					[formControl]="subTypeControl"
					[options]="subTypeOptions"
					[filter]="false"
					optionLabel="label"
					optionValue="value"
					filterBy="label"
					filterPlaceholder="Filter"
					[autoDisplayFirst]="false"
					showClear="true"
					placeholder="Select Subtype"
					styleClass="ui-g-12 no-padding"
					(onChange)="subTypeChanged()">
				</p-dropdown>
			</div>

			<div class="ui-g-12 ui-input-group">
				<p-checkbox
					name="checkChangeFile"
					label="Replace File"
					binary="true"
					[(ngModel)]="changeFile"
					(onChange)="editForm.updateValueAndValidity()"
					[disabled]="changeFileDisabled">
				</p-checkbox>
				<div *ngIf="changeFile"
					[ngClass]="{
						'ng-invalid': (storageType !== 'Referenced' && fileUploader?.files?.length !== 1)
					}">
					<div *ngIf="storageType !== 'Referenced'"
						class="ui-g-12 custom-field-wrapper file-container no-horizontal-padding">
						<div class="input-label">
							<label for="file">
								<strong>
									New File
								</strong>
							</label>
						</div>
						<p-fileUpload
							inputId="file"
							#fileUploader
							customUpload="true"
							[multiple]="false"
							[showUploadButton] = "false"
							[showCancelButton] = "false"
							[cancelLabel]="Cancel"
							(onSelect)="fileChanged('selected')"
							(onClear)="fileChanged('cleared')"
							(onRemove)="fileChanged('removed')">
							<ng-template pTemplate="content">
								<div class="drag-and-drop-file-prompt">
									(or drag the file here to change)
								</div>
							</ng-template>
						</p-fileUpload>
					</div>
					<div *ngIf="storageType !== 'Referenced' && fileUploader?.files?.length !== 1"
						class="ui-g-12 no-padding validation-message-container">
						<div
							class="validation-message file-message theme-color-red">
							A file is required.
						</div>
					</div>

					<div *ngIf="storageType === 'Referenced'"
						class="ui-g-12 custom-field-wrapper no-horizontal-padding">
						<div class="input-label">
							<label for="url">
								<strong>
									Url *
								</strong>
							</label>
						</div>
						<input
							inputId="url"
							[formControl]="urlControl"
							(input)="fileUrlChanged()"
							(change)="fileUrlChanged()"
							type='text'
							placeholder="http://some.website.com/somefile.pdf"
							class="width-100"
							[ngClass]="{
								'ng-invalid': (storageType === 'Referenced' && invalid('urlControl'))
							}"
							pInputText />
					</div>
					<div *ngIf="storageType === 'Referenced' && invalid('urlControl')"
						class="ui-g-12 no-padding validation-message-container">
						<div
							class="ui-g-12 no-padding validation-message theme-color-red">
							A valid url is required.
						</div>
					</div>
				</div>
			</div>

			<div class="ui-g-12 custom-field-wrapper">
				<div class="input-label">
					<label for="name">
						<strong>
							Name
						</strong>
					</label>
				</div>
				<div class="p-inputgroup extension">
					<input
						inputId="name"
						[formControl]="nameControl"
						type="text"
						maxlength="128"
						(change)="nameChanged()"
						[ngClass]="{
							'ng-invalid': (invalid('nameControl'))
						}"
						pInputText />
					<span class="p-inputgroup-addon">
						.{{extension}}
					</span>
				</div>
			</div>
			<div *ngIf="invalid('nameControl')"
				class="ui-g-12 no-padding validation-message-container">
				<div
					class="ui-g-12 no-padding validation-message theme-color-red">
					{{requiredValueMessage}}
				</div>
			</div>

			<div class="ui-g-12 custom-field-wrapper">
				<div class="input-label">
					<label for="description">
						<strong>
							Description
						</strong>
					</label>
				</div>
				<textarea
					class="panel-text"
					pInputTextarea
					[formControl]="descriptionControl"
					(input)="descriptionChanged()"
					(change)="descriptionChanged()"
					maxlength="256">
				</textarea>
			</div>
		</p-scrollPanel>

		<div class="ui-g-12 no-padding text-right button-set">
			<a class="text-link theme-color secondary-action-button"
				(click)="changeDisplayMode.emit('list')">
				Cancel
			</a>
			<button
				pButton
				label="Save"
				class="no-margin-right primary-action-button"
				(click)="onUpdateClicked()"
				[disabled]="editForm.invalid
					|| ((storageType !== 'Referenced' && fileUploader?.files?.length !== 1)
						&& changeFile === true)">
			</button>
		</div>
	</div>
</div>